import React from "react";
import { NavLink } from 'react-router-dom';
import "./Footer.scss";
import FooterLogo from "./img/footerlogo.svg";
import FooterImg from "./img/footerimg.png";

const currentYear = new Date().getFullYear();

const Main = () => {
  return (
    <footer>
      <div className="container">
        <div className="footerhead">
          <img src={FooterLogo} alt="Logo" className="footer-logo"/>
          <nav className="footer-navigation">
            <NavLink to="/">
              Token
            </NavLink>
            <NavLink to="#holding">
              Холдинг
            </NavLink>
            <NavLink to="#projects">
              Проекты
            </NavLink>
            <NavLink to="https://lime-fun.gitbook.io/white-paper" className="wp">
              Whitepaper
            </NavLink>
          </nav>
        </div>
        <div className="footerimg">
          <img src={FooterImg} alt="Logo" />
        </div>
        <div className="footerbottom">
          <div className="links">
            <a>Политика конфиденциальности</a>
            <a>Персональные данные</a>
          </div>
          <div className="copyright">
            <span>Lime © {currentYear}</span>
          </div>
        </div>
      </div>
      
    </footer>
  );
};

export default Main;
