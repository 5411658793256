import { QRProps } from 'antd/es/qr-code/interface';
import {QRCodeSVG} from 'qrcode.react';

const QrCodeGenerator = (props: QRProps) => {
    return (
        <QRCodeSVG {...props}  />
    );
};

export default QrCodeGenerator;
