import React, { useState, useEffect, useRef } from "react";
import {NavLink, useNavigate} from 'react-router-dom';
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount, useWeb3Modal } from "@web3modal/ethers/react";
import Logo from "./img/logo.svg";
import ruFlag from "./img/ru.svg";
import enFlag from "./img/en.svg";
import './Header.scss';

// 1. Get projectId
const projectId = '74370368811ba66914930f4fb4b97b9d';

// 2. Set chains
const mainnet = {
    chainId: 97,
    name: 'BNB Smart Chain Testnet',
    currency: 'tBNB',
    explorerUrl: 'https://testnet.bscscan.com',
    rpcUrl: 'https://bsc-testnet-rpc.publicnode.com',
};

// 3. Create a metadata object
const metadata = {
    name: 'lime',
    description: 'lime',
    url: '', // origin must match your domain & subdomain
    icons: ['avatar'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    metadata,
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 97, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeMode: 'dark',
    themeVariables: {
        '--w3m-font-family': 'Noto Sans Regular',
        '--w3m-accent': '#FFF',
        '--w3m-border-radius-master': '16px',
    },
});

const languageOptions = [
    { name: "RU", flag: ruFlag },
    { name: "EN", flag: enFlag },
];

const Header: React.FC = () => {
    const navigate = useNavigate();
    const { address, isConnected } = useWeb3ModalAccount();
    const { open } = useWeb3Modal();
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const [isConnectOpen, setIsConnectOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<{ name: string; flag: string }>(languageOptions[0]);

    const languageRef = useRef<HTMLDivElement>(null);
    const connectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (address) {
            localStorage.setItem("wallet",address);
            if (isConnectOpen) {
                navigate('/profile');
            }
        }else{
            localStorage.setItem("wallet","")
        }
    },[address, isConnected, isConnectOpen])

    const handleConnect = async () => {
        try {
            if (!isConnected) {
                setIsConnectOpen(true);
            }
            await open();
            console.log('Wallet connected');
        } catch (error) {
            console.error('Error connecting wallet:', error);
        }
    };

    const toggleLanguageMenu = () => {
        setIsLanguageOpen(!isLanguageOpen);
        setIsConnectOpen(false);
    };

    const selectLanguage = (lang: { name: string; flag: string }) => {
        setSelectedLanguage(lang);
        setIsLanguageOpen(false);
    };

    useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
        if (
            languageRef.current &&
            !languageRef.current.contains(event.target as Node)
        ) {
            setIsLanguageOpen(false);
        }

    };

    document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="header-box">
                <img src={Logo} alt="Logo" className="header-logo"/>
                <div className="hamburger-menu" onClick={toggleMenu}>
                    <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
                    <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
                    <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
                </div>
                <nav className={`header-navigation ${isMenuOpen ? "open" : ""}`}>
                    <NavLink to="/">Token</NavLink>
                    <NavLink to="#holding">Холдинг</NavLink>
                    <NavLink to="#projects">Проекты</NavLink>
                    <NavLink to="https://lime-fun.gitbook.io/white-paper" className="wp">
                    Whitepaper
                    </NavLink>
                    
                <div className="buttons mob">
                    <div className={`lang_switcher ${isLanguageOpen ? "open" : ""}`} ref={languageRef}>
                    <div className="lang-btn" onClick={toggleLanguageMenu}>
                        <img src={selectedLanguage.flag} alt={selectedLanguage.name} className="lang-icon" />
                        {selectedLanguage.name}
                    </div>
                    {isLanguageOpen && (
                        <ul className="lang-dropdown">
                        {languageOptions.map((lang) => (
                            <li key={lang.name} onClick={() => selectLanguage(lang)}>
                            <img src={lang.flag} alt={lang.name} className="lang-icon" /> {lang.name}
                            </li>
                        ))}
                        </ul>
                    )}
                    </div>
                    {isConnected && (
                        <div className="connect-container" ref={connectRef}>
                            <nav className="m_menu">
                            <ul>
                                <li><a href="/profile">Profile</a></li>
                            </ul>
                            </nav>
                        </div>
                    )}
                    <button className="header-button" onClick={handleConnect}>{!isConnected ? 'Connect' : 'Disconnect'}</button>
                </div>
                </nav>
                <div className="buttons">
                    <div className={`lang_switcher ${isLanguageOpen ? "open" : ""}`} ref={languageRef}>
                    <div className="lang-btn" onClick={toggleLanguageMenu}>
                        <img src={selectedLanguage.flag} alt={selectedLanguage.name} className="lang-icon" />
                        {selectedLanguage.name}
                    </div>
                    {isLanguageOpen && (
                        <ul className="lang-dropdown">
                        {languageOptions.map((lang) => (
                            <li key={lang.name} onClick={() => selectLanguage(lang)}>
                            <img src={lang.flag} alt={lang.name} className="lang-icon" /> {lang.name}
                            </li>
                        ))}
                        </ul>
                    )}
                    </div>
                    {isConnected && (
                        <div className="profile-btn"><a href="/profile">Profile</a></div>
                    )}
                    <button className={`header-button ${isConnected ? 'connected' : ''}`}  onClick={handleConnect}>{!isConnected ? 'Connect' : 'Disconnect'}</button>
                </div>
            </div>
        </header>
    );
};

export default Header;
