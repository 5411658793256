import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './styles.scss';
import Main from './pages/main/main';
import Profile from './pages/profile/profile';
import Owner from './pages/owner/owner';
import Referal from './pages/referal/referal';


function App() {


    return (
        <Router>
            <Header />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/owner" element={<Owner />} />
                    <Route path="/referal" element={<Referal />} />
                </Routes>
            <Footer />
        </Router>
    );
   }

export default App;
