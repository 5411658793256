import Web3 from 'web3';
import axios from "axios";
import { earlyInvestorsABI } from "./Abi/earlyInvestorsABI";
import { presaleABI } from "./Abi/presale";
import { Ref } from "./Abi/ref";
import { LFLToken } from "./Abi/token";
import { usdtAbi } from "./Abi/usdtAbi";
const web3 = new Web3("https://bsc-dataseed.binance.org/");
const presaleAddress = "0x2A1f112D32Db1c1e4E88c08a5639a71A2668F997";
const LFLTokenAddress = "0x968c835f96d38254c2Ca5d902367Bb5465A11B70";
const earlyInvestorsAddress = "0xd06b3DD4745f6879825CB76d7B75be99fc61dEA2";
const refAddress = "0x84390d5FBf12c023464501381f35e5414bF26A84";
export const USDTContAddress = "0x55d398326f99059ff775485246999027b3197955";
const contractPresale = new web3.eth.Contract(presaleABI, presaleAddress);
const contractEarlyInvestors = new web3.eth.Contract(earlyInvestorsABI, earlyInvestorsAddress);
const contractLFLToken = new web3.eth.Contract(LFLToken, LFLTokenAddress);
const contractReferral = new web3.eth.Contract(Ref, refAddress);
const contractUSDT = new web3.eth.Contract(usdtAbi, USDTContAddress);
export async function schedule(_address) {
  let dataArr = [];
  let index = 0;
  let data = await contractEarlyInvestors.methods.schedules(_address, index).call();
  while (data.totalAmount !== '0') {
    dataArr.push(data);
    index++;
    data = await contractEarlyInvestors.methods.schedules(_address, index).call();
  }
  return dataArr;
}
export async function balanceOfLFL(_account) {
  let balance = await contractLFLToken.methods.balanceOf(_account).call();
  return Number(balance) / 10 ** 4;
}
export async function balanceInUSDT(_tokenCount) {
  let discountPrice = await contractPresale.methods.discountPrice().call();
  let tokenPriceInUSDT = await contractPresale.methods.tokenPriceInUSDT().call();
  let CoursInUSDT = (discountPrice * tokenPriceInUSDT / 100) * _tokenCount;
  return CoursInUSDT;
}
export async function refCount(_address) {
  let refCount = await contractReferral.methods.referralCount(_address).call();
  return Number(refCount);
}
export async function userReferralList(_walletAddress) {
  let referralData = [];
  try {
    let refCount = await contractReferral.methods.referralCount(_walletAddress).call();
    refCount = Number(refCount);
    for (let index = 0; index < refCount; index++) {
      const referralAddress = await contractReferral.methods.userReferralList(_walletAddress, index).call();
      const bonus = await contractReferral.methods.referralBonuses(_walletAddress, referralAddress).call();
      referralData.push({
        address: referralAddress,
        bonus: Number(bonus) / 10 ** 4, // Преобразуем бонусы в читаемый формат
      });
    }
  } catch (error) {
    console.error("Error occurred while fetching user referral list:", error);
    throw new Error("Contract execution failed: " + error.message);
  }
  return referralData;
}
export async function refPercent() {
  let refPercent = await contractReferral.methods.refLevel1Percent().call();
  return Number(refPercent);
}
export async function totalBonus(_address) {
  let totalBonus = await contractReferral.methods.totalBonuses(_address).call();
  return Number(totalBonus);
}
export async function totalBonusCount(_address) {
  let bonusCount = await contractReferral.methods.totalBonuses(_address).call();
  return bonusCount;
}
export async function tokenPriceInUSDT() {
  let price = await contractPresale.methods.tokenPriceInUSDT().call();
  const priceFromWei = web3.utils.fromWei(price, 'ether');
  return priceFromWei;
}
export async function totalTokensSold() {
  let totalSold = await contractPresale.methods.totalTokensSold().call();
  return totalSold;
}
export async function totalTokensBuy() {
  let totalBuy = await contractPresale.methods.totalTokensBuy().call();
  return totalBuy;
}
export async function getLastSellTimestamp(userAddress) {
  try {
    const timestamp = await contractPresale.methods.lastSellTImeStamp(userAddress).call();
    return timestamp;
  } catch (error) {
    console.error("Ошибка при получении lastSellTImeStamp:", error);
    throw error;
  }
}
export async function getTimeForSale() {
  try {
    const timeForSale = await contractLFLToken.methods.timeForSale().call();
    return timeForSale;
  } catch (error) {
    console.error("Ошибка при получении timeForSale:", error);
    throw error;
  }
}
export async function canSellNow(userAddress) {
  try {
    const lastSellTimestamp = await getLastSellTimestamp(userAddress);
    const timeForSale = await getTimeForSale();
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= parseInt(lastSellTimestamp) + parseInt(timeForSale);
  } catch (error) {
    console.error("Ошибка при проверке возможности продажи:", error);
    throw error;
  }
}
export async function getTimeUntilNextSell(userAddress) {
  try {
    const lastSellTimestamp = await getLastSellTimestamp(userAddress);
    const timeForSale = await getTimeForSale();
    const currentTime = Math.floor(Date.now() / 1000);
    const nextSellTime = parseInt(lastSellTimestamp) + parseInt(timeForSale);
    const timeLeft = nextSellTime - currentTime;
    return timeLeft > 0 ? timeLeft : 0;
  } catch (error) {
    console.error("Ошибка при расчете времени до следующей продажи:", error);
    throw error;
  }
}
export function formatTimeMMSS(seconds) {
  if (seconds <= 0) return "00:00";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}
export async function buyTokens(amountInLIME, fromAddress) {
  try {
    if (typeof window.ethereum === 'undefined') {
      throw new Error("Ethereum не доступен. Убедитесь, что установлен MetaMask или другой кошелек.");
    }
    const web3 = new Web3(window.ethereum);
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    const account = accounts[0];
    if (!account) {
      throw new Error("Аккаунт не найден или не подключен.");
    }
    const referrer = localStorage.getItem("referrer");
    if (referrer) {
      console.log("Referrer found:", referrer);
      const transactions = await getTransactions(account);
      const hasPurchases = transactions.some(tx => tx.functionName === "buyWithUsdt");
      if (hasPurchases) {
        throw new Error("Невозможно привязать реферера после совершения покупок.");
      }
      await contractReferral.methods.setReferrerOnPurchase(account, referrer).send({
        from: account,
      });
      console.log("Referrer set successfully for address:", account);
      localStorage.removeItem("referrer");
      console.log("Referrer removed from localStorage.");
    }
    const priceInUSDT = await tokenPriceInUSDT();
    const amountInUSDT = amountInLIME * priceInUSDT;
    const amountInWei = web3.utils.toWei(amountInUSDT.toString(), 'ether');
    const contractPresale = new web3.eth.Contract(presaleABI, presaleAddress);
    const contractUSDT = new web3.eth.Contract(usdtAbi, USDTContAddress);
    const currentAllowance = await contractUSDT.methods.allowance(account, presaleAddress).call();
    if (Number(currentAllowance) < Number(amountInWei)) {
      console.log("Требуется approve для USDT...");
      try {
        const approveTx = await contractUSDT.methods.approve(presaleAddress, amountInWei).send({
          from: account
        });
        console.log("USDT успешно approved:", approveTx.transactionHash);
      } catch (error) {
        throw new Error("Ошибка при approve USDT: " + error.message);
      }
    }
    let estimateGas;
    try {
      estimateGas = await contractPresale.methods.buyWithUSDT(amountInWei).estimateGas({
        from: account
      });
    } catch (error) {
      if (error.message.includes("Returned error: Internal JSON-RPC error")) {
        throw new Error("Требуется разрешение на использование USDT. Пожалуйста, подтвердите транзакцию approve.");
      }
      throw new Error("Ошибка при оценке газа: " + error.message);
    }

    const tx = await contractPresale.methods.buyWithUSDT(amountInWei).send({
      from: account,
      gas: estimateGas.toString(),
    });

    console.log("Покупка завершена:", tx);
    return tx;
  } catch (error) {
    console.error("Ошибка при покупке токенов:", error);
    throw error;
  }
}
export async function buyBack(tokenAmount) {
  try {
    if (typeof window.ethereum === 'undefined') {
      throw new Error("Ethereum не доступен. Убедитесь, что установлен MetaMask.");
    }
    const web3 = new Web3(window.ethereum);
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    const account = accounts[0];
    console.log("Подключенный аккаунт:", account);
    const presaleAddress = "0x2A1f112D32Db1c1e4E88c08a5639a71A2668F997";
    const contractPresale = new web3.eth.Contract(presaleABI, presaleAddress);
    console.log("Создан контракт пресейла:", contractPresale);
    console.log("Доступные методы контракта:", Object.keys(contractPresale.methods));
    const tokenDecimals = 4;
    const amountInSmallestUnit = Math.floor(parseFloat(tokenAmount) * Math.pow(10, tokenDecimals)).toString();
    console.log("Количество токенов для продажи:", tokenAmount);
    console.log("Количество в минимальных единицах:", amountInSmallestUnit);
    const tokenAddress = await contractPresale.methods.token().call();
    console.log("Адрес токена:", tokenAddress);
    const tokenContract = new web3.eth.Contract([
      {
        "constant": true,
        "inputs": [{ "name": "_owner", "type": "address" }],
        "name": "balanceOf",
        "outputs": [{ "name": "balance", "type": "uint256" }],
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "name": "_spender", "type": "address" },
          { "name": "_value", "type": "uint256" }
        ],
        "name": "approve",
        "outputs": [{ "name": "", "type": "bool" }],
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [
          { "name": "_owner", "type": "address" },
          { "name": "_spender", "type": "address" }
        ],
        "name": "allowance",
        "outputs": [{ "name": "", "type": "uint256" }],
        "type": "function"
      }
    ], tokenAddress);
    console.log("Адрес владельца:", account);
    console.log("Адрес контракта:", presaleAddress);
    const currentAllowance = await tokenContract.methods.allowance(account, presaleAddress).call();
    console.log("Текущее разрешение (allowance):", currentAllowance);
    if (Number(currentAllowance) < Number(amountInSmallestUnit)) {
      console.log("Попытка разрешения перевода токенов...");
      const approveTx = await tokenContract.methods.approve(presaleAddress, amountInSmallestUnit).send({
        from: account
      });
      console.log("Разрешение получено, хэш транзакции:", approveTx.transactionHash);
    }
    console.log("Вызываем buyBack с параметром:", amountInSmallestUnit);
    const tx = await contractPresale.methods.buyBack(amountInSmallestUnit).send({
      from: account
    });
    console.log("Транзакция buyBack успешно отправлена, хэш:", tx.transactionHash);
    return tx;
  } catch (error) {
    console.error("Ошибка:", error);
    alert("Ошибка: " + error.message);
    throw error;
  }
}

const contractAddress = "0x2A1f112D32Db1c1e4E88c08a5639a71A2668F997";
const bscscanApiKey = "DXKIRKHM6KPGBW7D2IY3UZPM7VY3EDZ5E3";
async function getTransactionDetails(txHash, retries = 3, delay = 1000) {
  const detailUrl = `https://api.bscscan.com/api?module=proxy&action=eth_getTransactionByHash&txhash=${txHash}&apikey=${bscscanApiKey}`;
  for (let i = 0; i < retries; i++) {
    try {
      const response = await axios.get(detailUrl);
      if (response.data.result && response.data.result.input) {
        return response.data.result;
      }
      console.log(`Попытка ${i + 1} для ${txHash}: данных нет, ждем...`);
      await new Promise(resolve => setTimeout(resolve, delay));
    } catch (error) {
      console.log(`Ошибка запроса для ${txHash}: ${error.message}`);
      if (i === retries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  throw new Error(`Не удалось получить данные для ${txHash} после ${retries} попыток`);
}
export async function getTransactions(filterAddress) {
  const url = `https://api.bscscan.com/api?module=account&action=txlist&address=${contractAddress}&startblock=0&endblock=99999999&sort=desc&apikey=${bscscanApiKey}`;
  const response = await axios.get(url);
  if (response.data.status !== "1") {
    console.log("API не дало данные:", response.data.message);
    return [];
  }
  const web3 = new Web3();
  const buyWithUsdtSignature = "buyWithUSDT(uint256)";
  const buyBackSignature = "buyBack(uint256)";
  const encodedBuyWithUsdt = web3.eth.abi.encodeFunctionSignature(buyWithUsdtSignature);
  const encodedBuyBack = web3.eth.abi.encodeFunctionSignature(buyBackSignature);
  const preFilteredTxs = response.data.result.filter(tx =>
    tx.from.toLowerCase() === filterAddress.toLowerCase() ||
    tx.to?.toLowerCase() === filterAddress.toLowerCase()
  );
  const USDT_DECIMALS = 6;
  const TOKEN_DECIMALS = 4;
  const result = await Promise.all(preFilteredTxs.map(async (tx) => {
    try {
      const detailedTx = await getTransactionDetails(tx.hash);
      let usdtAmount = 0;
      let tokenAmount = 0;
      let tokenPriceAtPurchase = 0;
      console.log(`Обрабатываю транзакцию: ${tx.hash}, input: ${detailedTx.input}`);
      if (detailedTx.input.startsWith(encodedBuyWithUsdt)) {
        console.log(`Транзакция ${tx.hash} — это покупка (buyWithUsdt)`);
        const decoded = web3.eth.abi.decodeParameters(['uint256'], detailedTx.input.slice(10));
        const rawUsdt = decoded[0];
        usdtAmount = Number(rawUsdt) / 10 ** USDT_DECIMALS;
        const tokenPriceRaw = await contractPresale.methods.tokenPriceInUSDT().call();
        tokenPriceAtPurchase = Number(tokenPriceRaw) / 10 ** USDT_DECIMALS;
        tokenAmount = usdtAmount / tokenPriceAtPurchase;
        console.log(`Tx ${tx.hash}: Куплено ${tokenAmount} токенов за ${usdtAmount} USDT (курс: ${tokenPriceAtPurchase})`);
        return { 
          ...tx, 
          usdtAmount, 
          tokenAmount, 
          tokenPriceAtPurchase, 
          functionName: "buyWithUsdt" 
        };
      } else if (detailedTx.input.startsWith(encodedBuyBack)) {
        console.log(`Транзакция ${tx.hash} — это продажа (buyBack)`);
        const decoded = web3.eth.abi.decodeParameters(['uint256'], detailedTx.input.slice(10));
        const rawToken = decoded[0];
        tokenAmount = Number(rawToken) / 10 ** TOKEN_DECIMALS;
        return { 
          ...tx, 
          usdtAmount, 
          tokenAmount, 
          functionName: "buyBack" 
        };
      }
      console.log(`Транзакция ${tx.hash} не подходит под buyWithUsdt или buyBack, игнорируем.`);
      return null;
    } catch (error) {
      console.log(`Ошибка при обработке ${tx.hash}: ${error.message}`);
      return null;
    }
  }));
  const filteredResult = result.filter(tx => tx !== null);
  console.log("Все отфильтрованные транзакции (только buyWithUSDT и buyBack):", filteredResult);
  return filteredResult;
}
export async function timeForVesting() {
  let timeForVesting = await contractLFLToken.methods.timeForSale().call();
  return Number(timeForVesting);
}
export async function customRefPercent(_account) {
  let refPercent = await contractReferral.methods.getCustomPercentage(_account).call();
  return Number(refPercent);
}
export async function getDiscountPrice() {
  let discountPrice = await contractPresale.methods.discountPrice().call();
  console.log("res", discountPrice);
  return Number(discountPrice);
}