import React, { useEffect, useState } from 'react';
import './style.scss';
import { balanceOfLFL, schedule, tokenPriceInUSDT, refCount, refPercent, totalBonus, buyTokens, buyBack, getTransactions, timeForVesting, userReferralList, customRefPercent, getDiscountPrice,  getLastSellTimestamp, getTimeForSale, canSellNow, getTimeUntilNextSell, formatTimeMMSS } from '../../web3';
import circle from "./img/circle.svg";
import QrCodeGenerator from "../../components/QrCodeGenerator";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";

const Profile: React.FC = () => {
    const { address, isConnected } = useWeb3ModalAccount();
const [canSell, setCanSell] = useState<boolean>(false);
    const [timeUntilSell, setTimeUntilSell] = useState<number>(0);
    const [formattedTime, setFormattedTime] = useState<string>("00:00");
    const [loading, setLoading] = useState<boolean>(true);
    const [buyTokenCount, setBuyTokenCount] = useState<number>(0); // Для вкладки "Купить"
    const [sellTokenCount, setSellTokenCount] = useState<number>(0); // Для вкладки "Продать"
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [showQrPopup, setShowQrPopup] = useState<boolean>(false);
    const [balanceLFL, setBalanceLFL] = useState<any>();
    const [cliffData, setCliffData] = useState<any>([]);
    const [clifAmount, setCliffAmount] = useState<any>(0);
    const [priceInUSDT, setPriceInUSDT] = useState<any>(0);
    const [buyData, setBuyData] = useState<any>([]);
    const [refferalCount, setRefferalCount] = useState<any>(0);
    const [referralPercent, setReferralPercent] = useState<any>(0);
    const [totalBonuses, setTotalBonuses] = useState<any>(0);
    const [transactions, setTransactions] = useState<any>()
    const [vestingTime, setvestingTime] = useState<any>()
    const [refUserList, setRefUserList] = useState<any>([0])
    const [currentDiscountPrice, setCurrentDiscountPrice] = useState<number | null>(null);

    console.log("LFLBalance", balanceLFL);
    console.log("wallet", walletAddress);
    console.log("PriceInUSDT", priceInUSDT);
    
    // Функция для обновления статуса продажи
    const updateSellStatus = async () => {
      if (!isConnected || !walletAddress) return;
      
      try {
        setLoading(true);
        
        // Проверяем, может ли пользователь продавать токены
        const sellAllowed = await canSellNow(walletAddress);
        setCanSell(sellAllowed);
        
        if (!sellAllowed) {
          // Получаем время до следующей возможной продажи
          const timeLeft = await getTimeUntilNextSell(walletAddress);
          setTimeUntilSell(timeLeft);
          setFormattedTime(formatTimeMMSS(timeLeft));
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при обновлении статуса продажи:", error);
        setLoading(false);
      }
    };
    
    // Обратный отсчет
    useEffect(() => {
      let intervalId: NodeJS.Timeout;
      
      if (!canSell && timeUntilSell > 0) {
        intervalId = setInterval(() => {
          setTimeUntilSell(prevTime => {
            if (prevTime <= 1) {
              clearInterval(intervalId);
              updateSellStatus();
              return 0;
            }
            const newTime = prevTime - 1;
            setFormattedTime(formatTimeMMSS(newTime));
            return newTime;
          });
        }, 1000);
      }
      
      return () => {
        if (intervalId) clearInterval(intervalId);
      };
    }, [canSell, timeUntilSell]);
    useEffect(() => {
        if (isConnected && walletAddress) {
          // Загрузка существующих данных
          // ...
          
          // Загрузка статуса продажи
          updateSellStatus();
        }
      }, [isConnected, walletAddress]);
    useEffect(() => {
        getDiscountPrice()
            .then((res: number) => {
                console.log("discountPrice", res);
                setCurrentDiscountPrice(res);
            })
            .catch((error: Error) => {
                console.error("Error fetching discount price:", error);
                setCurrentDiscountPrice(null);
            });
        getTransactions(walletAddress).then(data => {
            console.log("Итоговые данные:", data);
            setTransactions(data);
        }).catch(err => {
            console.log("Ошибка:", err);
            setTransactions([]);
        });

        timeForVesting().then((res) => {
            setvestingTime(res)

        })
        if (walletAddress) {
            userReferralList(walletAddress).then((res) => {
                setRefUserList(res);
            });
        }
        refPercent().then((res:any)=>{
            setReferralPercent(res)            
        })
    }, [walletAddress]);
// Рендеринг UI
const renderSellStatus = () => {
    if (loading) {
      return <div className="loading-status">Загрузка...</div>;
    }
    
    if (canSell) {
      return (
        <div className="sell-status sell-available">
          <div className="status-text">Продажа доступна</div>
        </div>
      );
    } else {
      return (
        <div className="sell-status sell-unavailable">
          <div className="status-text">
            Ожидание периода продажи
            <div className="countdown-timer">{formattedTime}</div>
          </div>
        </div>
      );
    }
  };
    useEffect(() => {
        document.title = 'Profile';
    }, []);

    useEffect(() => {
        if (address) {
            setWalletAddress(address);
        } else {
            console.warn("No wallet address found in local storage.");
        }
    }, [isConnected, address]);

    useEffect(() => {
        if (!walletAddress) {
            console.warn("Wallet address is not set. Skipping API calls.");
            return;
        }
        balanceOfLFL(walletAddress)
            .then((res) => setBalanceLFL(res))
            .catch((err) => console.error("Error fetching balance:", err));

        schedule(walletAddress)
            .then((res: any) => setCliffData(res))
            .catch((err: any) => console.error("Error in schedule:", err));
        tokenPriceInUSDT().then((res: any) => setPriceInUSDT(res));
    }, [walletAddress]);

    useEffect(() => {
        let totalAmount = 0;
        for (let index = 0; index < cliffData?.length; index++) {
            let amount = Number(cliffData[index]?.totalAmount)
            totalAmount += amount
        }
        setCliffAmount(totalAmount)
    }, [cliffData[0]?.totalAmount])


    useEffect(() => {
        if (!walletAddress) {
            console.warn("Wallet address is not set. Skipping API calls.");
            return;
        }
        refCount(walletAddress).then((res: any) => setRefferalCount(res));
        customRefPercent(walletAddress).then((res: any) => setReferralPercent(res));
        totalBonus(walletAddress).then((res: any) => setTotalBonuses(res));
        console.log("Count",refferalCount);
    }, [walletAddress])
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const referrerAddress = queryParams.get("refId");
        if (referrerAddress) {
            localStorage.setItem("referrer", referrerAddress);
        }
    }, []);
    // Копировать
    const copyToClipboard = () => {
        if (walletAddress) {
            navigator.clipboard.writeText(walletAddress);
            alert("Wallet address copied to clipboard!");
        } else {
            console.warn("No wallet address to copy.");
        }
    };
    const copyRefLink = () => {
        const referralLink = `https://lime.fun/?refId=${walletAddress}`;
        if (walletAddress) {
            navigator.clipboard.writeText(referralLink);
            alert("Link copied to clipboard!");
        } else {
            console.warn("No link address to copy.");
            alert("Before connect wallet");
        }
    };
    useEffect(() => {
        if (walletAddress) {
            const timeout = setTimeout(() => {
                localStorage.removeItem("walletAddress");
                console.log("Wallet address cleared from localStorage after timeout.");
            }, 48 * 60 * 60 * 1000);
            return () => clearTimeout(timeout);
        }
    }, [walletAddress]);
    // Попап qr
    const QrPopup = () => {
        setShowQrPopup(!showQrPopup);
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const popup = document.getElementById('qr-popup');
            if (popup && !popup.contains(event.target as Node) && showQrPopup) {
                setShowQrPopup(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showQrPopup]);

    // Покупка
    const handleBuyToken = async () => {
        try {
            if (buyTokenCount <= 0) {
                alert('Пожалуйста, введите количество токенов для покупки');
                return;
            }
            
            await buyTokens(buyTokenCount, walletAddress);
            alert('Транзакция успешно отправлена!');
            
            const newBalance = await balanceOfLFL(walletAddress);
            setBalanceLFL(newBalance);
        } catch (error) {
            console.error("Ошибка при покупке:", error);
            alert('Произошла ошибка при покупке токенов: ' + (error as Error).message);
        }
    };

    const handleBuyBack = async () => {
        try {
            if (!walletAddress) {
                alert('Please connect your wallet first');
                return;
            }
            if (sellTokenCount <= 0) {
                alert('Пожалуйста, введите количество токенов для продажи');
                return;
            }
            
            const confirmSell = window.confirm(`Вы уверены, что хотите продать ${sellTokenCount} LIME токенов?`);
            if (!confirmSell) {
                return;
            }
            
            console.log("Вызов функции buyBack для продажи токенов...");
            await buyBack(sellTokenCount.toString());
            alert('Транзакция продажи токенов успешно отправлена!');
            
            const newBalance = await balanceOfLFL(walletAddress);
            setBalanceLFL(newBalance);
            await updateSellStatus();
        } catch (error) {
            console.error("Ошибка при продаже:", error);
            alert('Произошла ошибка при продаже токенов: ' + (error as Error).message);
        }
    };


    const balanceInUSDT = (balanceLFL || 0) * Number(priceInUSDT);
    const [activeTab, setActiveTab] = useState("deposit");
    useEffect(() => {
        const hash = window.location.hash.replace("#", "");
        if (hash) {
            setActiveTab(hash);
        }
    }, []);
    const changeTab = (tab: string) => {
        setActiveTab(tab);
        window.location.hash = tab;
    };
    return (
        <div className="profile">
            <div className="container">
                <div className="balance_box">
                    <div className="profile-img">
                        <img src={circle} />
                    </div>
                    <div className="profile-info">
                        <div className="wallet-address">
                            <div className="address" onClick={QrPopup}>{walletAddress || "Not connected"}{" "}</div>
                            <button className="copy-button" onClick={copyToClipboard}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="icon-copy"
                                >
                                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                </svg>
                            </button>
                        </div>
                        <div className="address-block">
                            <div>
                                <div className="balance">
                                    {balanceLFL} <span className="abb">LIME</span>
                                </div>
                                <div className="cliff" >{clifAmount || 0} lime</div>
                                <div className="convert">
                                    {balanceInUSDT.toFixed(2)} <span className="abb">USDT</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="lines">
                        <div className="line1"></div>
                        <div className="line2"></div>
                    </div>
                </div>
                <div className="tabs">
                    <div className="tab-buttons">
                        <div
                            className={activeTab === "deposit" ? "active" : ""}
                            onClick={() => changeTab("deposit")}
                        >
                            Депозит
                        </div>
                        <div
                            className={activeTab === "sell" ? "active" : ""}
                            onClick={() => changeTab("sell")}
                        >
                            Продать
                        </div>
                        <div
                            className={activeTab === "history" ? "active" : ""}
                            onClick={() => changeTab("history")}
                        >
                            История
                        </div>
                        <div
                            className={activeTab === "referral" ? "active" : ""}
                            onClick={() => changeTab("referral")}
                        >
                            Партнеры
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className={`tab deposit ${activeTab === "deposit" ? "active_tab" : ""}`}>
                            <div className="input">
                                <input
                                    type="number"
                                    placeholder="Укажите количество токенов"
                                    value={buyTokenCount || ''}
                                    onChange={(e) => setBuyTokenCount(Number(e.target.value))}
                                />
                                <div className="currency">1 LIME = {Number(priceInUSDT)} USDT</div>
                            </div>
                            <div className="or">или</div>
                            <div className="input">
                                <input
                                    type="number"
                                    placeholder="Сумма к оплате в USDT"
                                    value={(buyTokenCount * Number(priceInUSDT)).toFixed(2)}
                                />
                            </div>
                            <div className="btn" onClick={handleBuyToken}>Купить</div>
                        </div>
                        <div className={`tab sell ${activeTab === "sell" ? "active_tab" : ""}`}>
                            <div className="input">
                                <input
                                    type="number"
                                    placeholder="Укажите количество токенов"
                                    value={sellTokenCount || ''}
                                    onChange={(e) => setSellTokenCount(Number(e.target.value))}
                                />
                            </div>
                            <div className="or"></div>
                            <div className="input">
                                <input
                                    type="number"
                                    value={(sellTokenCount * Number(priceInUSDT) * (Number(currentDiscountPrice) / 100)).toFixed(2)}
                                    placeholder="0"
                                    disabled
                                />
                                <div className="currency">Вы получите</div>
                            </div>
                            <div className={`btn ${canSell ? 'active' : 'disabled'}`} onClick={canSell ? handleBuyBack : undefined}>Продать</div>
                            <div className="sell-status-section">
                                <h3>Статус продажи токенов:</h3>
                                {renderSellStatus()}
                            </div>
                        </div>
                        <div className={`tab history ${activeTab === "history" ? "active_tab" : ""}`}>
                            <div className="table1">
                                <div className="lines">
                                    <div className="line4"></div>
                                    <div className="line5"></div>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Hash</th>
                                            <th>Amount</th>
                                            <th>Rate</th>
                                            <th>Total</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                            {/* <th>Клифф период</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactions && transactions?.length > 0 ? (
                                            transactions.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item?.hash ? (
                                                            <a 
                                                            href={`https://testnet.bscscan.com/tx/${item.hash}`} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer"
                                                            title={item.hash}
                                                            className="text-blue-500 hover:underline"
                                                            style={{ color: "white" }}
                                                            >
                                                            {`${item.hash.slice(0, 6)}...${item.hash.slice(-6)}`}
                                                            </a>
                                                        ) : "N/A"}
                                                    </td>
                                                    <td>{item.functionName === "buyWithUsdt" ? item.tokenAmount?.toFixed(1) || "N/A" : item.functionName === "buyBack" ? item.tokenAmount?.toFixed(1) || "N/A" : "N/A"} LIME</td>
                                                    <td>
                                                        {item.tokenAmount && Number(item.usdtAmount) ? (
                                                            <>
                                                            {(Number(item.usdtAmount) / Number(item.tokenAmount) * 1e-12).toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}
                                                            </>
                                                        ) : "—"}
                                                    </td>
                                                    <td>
                                                        {item.functionName === "buyWithUsdt" ? (
                                                            <span className="text-green-600">
                                                            {item.tokenAmount && 
                                                            (Number(item.usdtAmount) / Number(item.tokenAmount) * 1e-12) ? 
                                                                (Number(item.tokenAmount) * 
                                                                (Number(item.usdtAmount) / Number(item.tokenAmount) * 1e-12)).toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                                }) : "0.00"}
                                                            </span>
                                                        ) : item.functionName === "buyBack" ? (
                                                            "—"
                                                        ) : (
                                                            "N/A"
                                                        )}
                                                    </td>
                                                    <td style={{ color: item?.functionName === "buyWithUsdt" ? "green" : item?.functionName === "buyBack" ? "red" : "black" }} >{item?.functionName ? item.functionName === "buyWithUsdt" ? "Buy" : item.functionName === "buyBack" ? "Sell" : item.functionName.replace(/\(.*\)/, '') : "Buy"}</td>
                                                    <td>{item?.isError === "0" ? "Complete" : "Error"}</td>
                                                    <td>
                                                        {item?.timeStamp
                                                            ? new Date(Number(item?.timeStamp) * 1000).toLocaleString()
                                                            : "N/A"}
                                                    </td>
                                                    {/*<td>
                                                        {vestingTime}
                                                    </td>*/}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={7}>No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={`tab referral ${activeTab === "referral" ? "active_tab" : ""}`}>
                            <div className="stat">
                                <div className="stat__box">
                                    <div className="stat__items">
                                        <div className="stat__item">
                                            <div className="stat__title">{referralPercent}%</div>
                                            <div className="stat__txt">Вы получаете за транзакции партнёров</div>
                                        </div>
                                        <div className="stat__item">
                                            <div className="stat__title">{refferalCount}</div>
                                            <div className="stat__txt">Реферальных партнёров вы пригласили</div>
                                        </div>
                                        <div className="stat__item">
                                            <div className="stat__title">{(totalBonuses / 10 ** 4).toFixed(2)} <span>LIME</span></div>
                                            <div className="stat__txt">Ваш суммарный доход по реферальной программе</div>
                                        </div>
                                    </div>
                                    <div className="stat__invite">
                                        <div className="invite__text">Ваша реферальная ссылка:</div>
                                        <div className="invite__url">
                                            https://lime.fun/?refId={walletAddress}
                                            <div className="copy" onClick={copyRefLink}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="stat__qr">
                                    <QrCodeGenerator value={`https://lime.fun/?refId=${walletAddress}`} />
                                </div>
                            </div>
                            <div className="table2">
                                <div className="lines">
                                    <div className="line3"></div>
                                    <div className="line4"></div>
                                    <div className="line5"></div>
                                    <div className="line6"></div>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Реферальный партнёр</th>
                                            <th>Ваш доход</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {refUserList?.map((item: any, index: any) => (
                                        <tr key={index}>
                                        <td>{item?.address || "0x000...000"}</td>
                                        <td>{item?.bonus ?? 0} LIME</td> {/* Отображаем бонусы */}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-actions"></div>
                <br></br>

                <div className="backgrounds">
                    <div className="bg_1"></div>
                </div>
            </div>
            {showQrPopup && (
                <div className="qr-popup-overlay">
                    <div id="qr-popup" className="qr-popup">
                        <div className="popup-header">
                            <h3>Wallet QR Code</h3>
                            <button className="close-button" onClick={QrPopup}>×</button>
                        </div>
                        <div className="popup-content">
                            {walletAddress ? (
                                <>
                                    <QrCodeGenerator value={walletAddress} size={200} />
                                    <p className="wallet-text">{walletAddress}</p>
                                </>
                            ) : (
                                <p>No wallet connected</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile;
