import React, { useEffect, useState } from 'react';
import "./main.scss";
import Forblogo from "./img/forblogo.svg";
import Forblogo2 from "./img/forblogo2.svg";
import Hands from "./img/hands2.png";
import LimeHolding from "./img/lh.png";
import Lh1 from "./img/holding_line.svg";
import LimeToken from "./img/lt.png";
import Tl1 from "./img/token_line1.svg";
import Rwa from "./img/rwa.png";
import Rwal1 from "./img/rwa_line1.svg";
import Rwal2 from "./img/rwa_line2.svg";
import Projects from "./img/project.png";
import Partners from "./img/partners.png";
import Chart from "./img/chart.jpg";
import Calc from "./img/calc.png";
import Social from "./img/social.png";
import { tokenPriceInUSDT } from '../../web3';


const Main = () => {
        const [tokenPriceUsdt,setTokenPriceUSDT]= useState();

        useEffect(()=>{
            tokenPriceInUSDT().then((res:any)=>{
              setTokenPriceUSDT(res)
              console.log("PriceUSDT",res);
            })
        },[])
        useEffect(() => {
            document.title = 'Lime';
        }, []);

        useEffect(() => {
            const bgElement = document.querySelector(".bg_2") as HTMLElement;
        
            const handleMouseMove = (event: MouseEvent) => {
              const { clientX, clientY } = event;
              const xOffset = (clientX / window.innerWidth - 0.5) * 40; // Двигаем по X
              const yOffset = (clientY / window.innerHeight - 0.5) * 40; // Двигаем по Y
        
              bgElement.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
            };
        
            window.addEventListener("mousemove", handleMouseMove);
        
            return () => {
              window.removeEventListener("mousemove", handleMouseMove);
            };
          }, []);
  return (
    <div className="main-page">
      <section className="present">
        <div className="container">
            <div className="present-content">
                <div className="left">
                    <div>L<span className="name">i</span>me Token</div>
                    <div className="desc">INNOVATION</div>
                </div>
                <div className="right">
                    <div className="text">
                        <div className="text__top">Стабильные</div>
                        <div className="text__bottom">инвестиции будущего</div>
                    </div>
                </div>
            </div>
            <div className="backgrounds">
                <img src={Hands}  className="bg_1"/>
                <div className="bg_2"></div>
            </div>            
            <div className="present-btn">
                <div className="buy-btn">Купить Lime Token</div>
                <div className="more">Подробнее</div>
            </div>
        </div>
      </section>
      <section className="holding">
        <div className="container">
            <h2>Lime холдинг</h2>
            <div className="holding_box">
                <div className="btn_box">Подробнее</div>
                <div className="text_box">Инновационный холдинг, разрабатывающий современные, безопасные и высокотехнологичные решения, меняющие мир</div>
            </div>
            <div className="backgrounds">
                <img src={LimeHolding}  className="bg_1"/>
                <img src={Lh1}  className="bg_2"/>
            </div>
        </div>
      </section>
      <section className="token">
        <div className="container">
            <h2 className="right">Lime token</h2>
            <div className="token_box">
                <div className="text_box">Цифровой актив, обеспечивающий доходность до 60% годовых и предоставляющий доступ к эксклюзивным предложениям холдинга Lime</div>
                <div className="btn_box">Подробнее</div>
            </div>
            <div className="backgrounds">
                <img src={LimeToken}  className="bg_1"/>
                <img src={Tl1}  className="bg_2"/>
            </div>
        </div>
      </section>
      <section className="rwa">
        <div className="container">
            <h2>Гарантия безопасности (RWA)</h2>
            <div className="rwa_box">
            <div className="btn_box">Подробнее</div>
                <div className="text_box">Lime Token привязан к реальным активам (Real-World Assets), обеспечивая стабильность и защиту от волатильности</div>
            </div>
            <div className="backgrounds">
                <img src={Rwa} className="bg_1"/>
                <img src={Rwal1} className="bg_2"/>
                <img src={Rwal2} className="bg_3"/>
            </div>
        </div>
      </section>
      <section className="projects">
        <div className="container">
            <h2 className="center">Проекты холдинга</h2>
            <div className="carusel">
            <div className="direct_left">Экологические проекты</div>
                <div className="project">
                    <div className="name">Name Project</div>
                    <img src={Projects} />
                    <div className="desc">
                        <div className="title">Name Project</div>
                        <div className="text">Lime Token привязан к реальным активам (Real-World Assets), обеспечивая стабильность и защиту от волатильности</div>
                    <div className="controls">
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="direct_right">Технологические проекты</div>
            </div>            
            <div className="backgrounds">
                <div className="bg_1"></div>
                <div className="bg_2"></div>
                <div className="bg_3"></div>
                <div className="bg_4"></div>
                <div className="bg_5"></div>
            </div>
        </div>
      </section>
      <section className="invest">
        <div className="container">
            <h2>Как инвестировать в Lime token</h2>
            <div className="invest_box">
                <div className="chart">
                    <div>{tokenPriceUsdt}$</div>
                    <img src={Chart} />
                </div>
                <div className="calc">
                    <div className="text">Узнайте сколько выгоды вы получите вложив сейчас</div>
                    <img src={Calc} />
                    <div className="controls">
                        <div className="minus">-</div>
                        <div className="btn">Инвестировать</div>
                        <div className="plus">+</div>
                    </div>
                </div>
            </div>
            <div className="backgrounds">
                <div className="bg_1"></div>
                <div className="bg_2"></div>
                <div className="bg_3"></div>
            </div>
        </div>
      </section>
      <section className="press">
        <div className="container">
            <h2 className="right">О нас пишут</h2>
        </div>
        <div className="forbes-container animated">
            <div className="forbes animated">
                <div className="forb"><img src={Forblogo} /><span className="forb-url">forbes.com</span></div>
                <div className="forb"><img src={Forblogo2}/><span className="forb-url">time.com</span></div>
                <div className="forb"><img src={Forblogo} /><span className="forb-url">forbes2.com</span></div>
                <div className="forb"><img src={Forblogo2} /><span className="forb-url">time3.com</span></div>
                <div className="forb"><img src={Forblogo} /><span className="forb-url">forbes3.com</span></div>
                <div className="forb"><img src={Forblogo2} /><span className="forb-url">time4.com</span></div>
                <div className="forb"><img src={Forblogo} /><span className="forb-url">forbes4.com</span></div>
            </div>
            <div aria-hidden="true" className="forbes animated">
                <div className="forb"><img src={Forblogo} /><span className="forb-url">forbes.com</span></div>
                <div className="forb"><img src={Forblogo2}/><span className="forb-url">time.com</span></div>
                <div className="forb"><img src={Forblogo} /><span className="forb-url">forbes2.com</span></div>
                <div className="forb"><img src={Forblogo2} /><span className="forb-url">time3.com</span></div>
                <div className="forb"><img src={Forblogo} /><span className="forb-url">forbes3.com</span></div>
                <div className="forb"><img src={Forblogo2} /><span className="forb-url">time4.com</span></div>
                <div className="forb"><img src={Forblogo} /><span className="forb-url">forbes4.com</span></div>
            </div>
        </div>
      </section>
      <section className="partners">
        <div className="container">
            <h2>Партнёры</h2>
            <div className="partners_box"></div>
            <div className="backgrounds">
                <img src={Partners}  className="bg_1"/>
                <div className="bg_2"></div>
                <div className="bg_3"></div>
                <div className="bg_4"></div>
                <div className="bg_5"></div>
            </div>
        </div>
      </section>
      <section className="news">
        <div className="container">
            <h2 className="right">Новости холдинга</h2>
            <div className="news_box">
                <div className="icons">
                    <div className="wa"></div>
                    <div className="fb"></div>
                    <div className="tg"></div>
                    <div className="in"></div>
                </div>
                <div className="text_box">Любой текст что только нужен проекту, тут в прототипе решили что согласуем текст по позже</div>
            </div>
            <div className="backgrounds">
                <img src={Social}  className="bg_1"/>
                <div className="bg_2"></div>
                <div className="bg_3"></div>
            </div>
        </div>
      </section>
    </div>
  );
};

export default Main;
