import React, { useEffect, useState } from 'react';
import { totalTokensBuy, totalTokensSold } from '../../web3';
import './style.scss';

const Owner: React.FC = () => {
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [totalSold, setTotalSold] = useState<any>(0);
    const [totalBuy, setTotalBuy] = useState<any>(0);


    useEffect(() => {
        document.title = 'Owner Page';
    }, []);

    useEffect(() => {
        const storedAddress = localStorage.getItem("wallet");
        if (storedAddress) {
            setWalletAddress(storedAddress);
        } else {
            console.warn("No wallet address found in local storage.");
        }
    }, []);

    useEffect(() => {
        totalTokensSold().then((res: any) => setTotalSold(res));
        totalTokensBuy().then((res: any) => setTotalBuy(res));
    }, [walletAddress]);




    return (
        <div className="profile">
        <div className="container">
            <h1>Statistics for owner</h1>
            <p>Всего продано токенов: {totalSold} LFL</p>
            <p>Всего куплено токенов: {totalBuy} LFL</p>
        </div>
        </div>
    );
};

export default Owner;
