import React, { useEffect, useState } from 'react';
import './style.scss';
import { refCount, refPercent, totalBonus, userReferralList } from '../../web3';

const Referal: React.FC = () => {
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [refferalCount, setRefferalCount] = useState<any>(0);
    const [referralPercent, setReferralPercent] = useState<any>(0);
    const [totalBonuses, setTotalBonuses] = useState<any>(0);

    useEffect(() => {
        const storedAddress = localStorage.getItem("wallet");
        if (storedAddress) {
            setWalletAddress(storedAddress);
            console.log("sssss");
            
        } else {
            console.warn("No wallet address found in local storage.");
        }
    }, []);

    useEffect(() => {
        if 
        (!walletAddress) {
            console.warn("Wallet address is not set. Skipping API calls.");
            return;
        }

        refCount(walletAddress).then((res: any) => setRefferalCount(res));
        refPercent().then((res: any) => setReferralPercent(res));
        totalBonus(walletAddress).then((res: any) => setTotalBonuses(res));
        console.log("sss",refferalCount);
        



    }, [walletAddress])

    useEffect(() => {
        document.title = 'Referal Page';
    }, []);

    return (
        <div className="referal">
            <div className="container">
                <h1>Referal</h1>
                <div className="card">
                    <div className="card__item">
                        <div className="card__title">{refferalCount}</div>
                        <div className="card__txt">Количество рефералов</div>
                    </div>
                    <div className="card__item">
                        <div className="card__title">{referralPercent}%</div>
                        <div className="card__txt">Процент бонуса</div>
                    </div>
                    <div className="card__item">
                        <div className="card__title">{totalBonuses} LFL</div>
                        <div className="card__txt">Всего заработано</div>
                    </div>
                </div>
                <h2>Referal Statistic</h2>
                <table className="custom-table">
                    <thead>
                        <tr>
                            <th>Кошелек</th>
                            <th>Количество</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0x123...abc</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>0x456...def</td>
                            <td>200</td>
                        </tr>
                        <tr>
                            <td>0x789...ghi</td>
                            <td>300</td>
                        </tr>
                        <tr>
                            <td>0xabc...jkl</td>
                            <td>400</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Referal;
